import { createContext, useContext, useState } from 'react';

import { shuffle } from '../helpers/common/array';
import { getAlbumList, getInitialTrack, getTrackList } from '../helpers/common/audio';
import { getData, getDataParams, getEventList, getVideoList } from '../helpers/data';

const DataContext = createContext(undefined);

const {
  audio: audioData,
  video: videoData,
  event: eventData,
} = getData();

const {
  showAll,
  shuffleLists,
} = getDataParams();

const transform = shuffleLists ? shuffle : value => value;

const videoList = transform(getVideoList(videoData, showAll));
const eventList = transform(getEventList(eventData, showAll));
const albumList = transform(getAlbumList(audioData, showAll));
const trackList = transform(getTrackList(audioData, showAll));
const initialTrack = getInitialTrack(trackList);

export const DataContextProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = useState(initialTrack);

  return (
    <DataContext.Provider
      value={{
        initialTrack,
        currentTrack,
        setCurrentTrack,
        videoList,
        eventList,
        albumList,
        trackList,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error('useDataContext must be used within a DataContextProvider');
  }
  return context;
};
