import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export const getStyleValue = (element, property) => Number.parseInt(window.getComputedStyle(element)[property], 10);

export const scrollTop = elementSelector => {
  const scrollElement = document.querySelector(elementSelector) || document.documentElement;

  scrollElement.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const scrollTo = (elementSelector, referenceSelector, scrollSelector) => {
  const element = document.querySelector(elementSelector);

  if (element) {
    const elementPosition = element.getBoundingClientRect().top - getStyleValue(element, 'marginTop');
    const referenceElement = document.querySelector(referenceSelector);
    const offsetPosition = referenceElement
      ? elementPosition - referenceElement.getBoundingClientRect().top
      : elementPosition + window.pageYOffset;
    const scrollElement = document.querySelector(scrollSelector) || document.documentElement;

    scrollElement.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export const isTouch = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const handleLoadWindow = callback => {
  if (document.readyState === 'complete') {
    callback();
  } else {
    const callbackAndCleanup = () => {
      callback();
      window.removeEventListener('load', callbackAndCleanup);
    };

    window.addEventListener('load', callbackAndCleanup);
  }
};
