import classNames from 'classnames';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AlbumList } from './components/AlbumList/AlbumList';
import { EventList } from './components/EventList/EventList';
import { Navigation } from './components/Navigation/Navigation';
import { Player } from './components/Player/Player';
import { TrackList } from './components/TrackList/TrackList';
import { VideoList } from './components/VideoList/VideoList';
import { useDataContext } from './context/data-context';
import { updateApp } from './helpers/common/update';
import { getConfig } from './helpers/data';

import './App.css';

const {
  artistId,
  links,
  routes,
  title,
} = getConfig();

function App() {
  const {
    trackList,
    albumList,
    videoList,
    eventList,
  } = useDataContext();

  useEffect(() => {
    updateApp();
  }, []);

  useEffect(() => {
    document.title = title;
  }, []);

  const renderRoutes = () => {
    const components = [
      {
        id: 'tracks',
        component: <TrackList trackList={trackList} />,
      },
      {
        id: 'albums',
        component: <AlbumList albumList={albumList} />,
      },
      {
        id: 'videos',
        component: <VideoList videoList={videoList} />,
      },
      {
        id: 'events',
        component: <EventList eventList={eventList} />,
      },
    ];

    return routes.map(({ id, path }) => {
      const component = components.find(component => component.id === id)?.component || null;

      return (
        <Route
          key={id}
          path={path}
          element={component}
        >
          {path !== '/' ? (
            <Route
              path={':id'}
              element={component}
            />
          ) : null}
        </Route>
      );
    });
  };

  return (
    <div className={classNames(
      'App',
      artistId,
    )}>
      <div className="main">
        <BrowserRouter>
          <Routes>
            {renderRoutes()}
            <Route
              path="*"
              element={(
                <Navigate to="/" />
              )}
            />
          </Routes>
          <Player />
          <Navigation
            items={[
              ...routes,
              ...links,
            ]}
          />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
