import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getAudioElement } from '../../helpers/common/audio';
import { handleLoadWindow, scrollTo, scrollTop } from '../../helpers/common/screen';
import { Image } from '../common/Image';
import { Link } from '../common/Link';

import './VideoList.css';

export const VideoList = ({ videoList }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    handleLoadWindow(() => {
      scrollTo(`.video-${id}`, '.videos');
    });
  }, [id]);

  const handleClickVideo = useCallback(video => {
    getAudioElement().pause();
    navigate(`/videos/${video.id}`);
  }, [navigate]);

  return (
    <div className="videos">
      {videoList.map(video => {
        const isCurrentVideo = id === video.id;

        return (
          <div
            key={video.id}
            className={classNames(
              'video',
              `video-${video.id}`,
              isCurrentVideo && 'current',
              'inline-block',
            )}
          >
            <Link
              to={video.url}
              className="block"
              onClick={() => {
                handleClickVideo(video);
              }}
            >
              <Image
                className="block"
                src={video.imgPath}
                alt={video.title}
              />
              <p className="description">{video.title}</p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

VideoList.propTypes = {
  videoList: PropTypes.array.isRequired,
};
