import PropTypes from 'prop-types';

import { Icon } from '../common/Icon';
import { Link } from '../common/Link';

import './Navigation.css';

export const Navigation = ({ items }) => (
  <div className="navigation">
    <div className="nav-item-wrapper">
      {items.map(({ id, path, icon, title }) => (
        <div
          key={id}
          className="nav-item"
        >
          <Link to={path}>
            <Icon id={icon} />
            <div className="description">
              {title}
            </div>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

Navigation.propTypes = {
  items: PropTypes.array.isRequired,
};
