import { fetchJSON } from './fetch';
import { redirect } from './navigation';

export const updateApp = () => {
  if (process.env.NODE_ENV === 'production') {
    fetchJSON(`/version.json?${new Date().getTime()}`, true).then(data => {
      const params = new URLSearchParams(window.location.search);
      const time = Number.parseInt(params.get('time'), 10);

      if (!time && app.time < data.time) {
        if (window.confirm('Ein Update ist verfügbar!\nSoll es starten?')) {
          params.set('time', data.time);
          redirect(`${window.location.pathname}?${params.toString()}`);
        }
      }
    });
  }
};
