import { storage } from './storage';

export const setMediaSession = track => {
  if ('mediaSession' in navigator) {
    // eslint-disable-next-line no-undef
    navigator.mediaSession.metadata = new MediaMetadata({
      title: track.tag.title,
      artist: track.tag.artist,
      album: track.tag.album,
      artwork: [
        { src: track.imgPath, sizes: '96x96', type: 'image/jpg' },
        { src: track.imgPath, sizes: '128x128', type: 'image/jpg' },
        { src: track.imgPath, sizes: '192x192', type: 'image/jpg' },
        { src: track.imgPath, sizes: '256x256', type: 'image/jpg' },
        { src: track.imgPath, sizes: '384x384', type: 'image/jpg' },
        { src: track.imgPath, sizes: '512x512', type: 'image/jpg' },
      ],
    });
  }
};

export const getAudioElement = () => document.querySelector('audio');

export const setStoredTrack = track => storage.set('audio:currentTrack', track);

export const getStoredTrack = () => storage.get('audio:currentTrack');

export const loadAudio = (track, play = false) => {
  const audio = getAudioElement();

  setMediaSession(track);
  setStoredTrack({
    path: track.path,
  });
  audio.src = track.path;
  if (play) {
    audio.play();
  }
};

export const isNotHidden = item => item.hidden !== true;

export const is = item => !!item;

export const getArtistList = (audioData, showAll) =>
  audioData.artists
    .filter(showAll ? is : isNotHidden);

export const getAlbumList = (audioData, showAll) =>
  getArtistList(audioData, showAll)
    .flatMap(artist => artist.items.filter(showAll ? is : isNotHidden));

export const getTrackList = (audioData, showAll) =>
  getAlbumList(audioData, showAll)
    .flatMap(album => album.tracks.filter(showAll ? is : isNotHidden));

export const getTrackIndex = (trackList, track) => trackList.findIndex(item => item.path === track.path);

export const getNextTrackIndex = (trackList, track) => (getTrackIndex(trackList, track) + 1) % trackList.length;

export const getInitialTrackIndex = trackList => getTrackIndex(trackList, getStoredTrack() || trackList[0]);

export const getInitialTrack = trackList => trackList[getInitialTrackIndex(trackList)] || trackList[0];

export const getNextTrack = (trackList, currentTrack) => trackList[getNextTrackIndex(trackList, currentTrack)];
