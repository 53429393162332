import config from '../config/app.json';
import { is, isNotHidden } from './common//audio';
import { getSearchParams, getSubDomain } from './common/url';

export const getConfig = () => config;

export const getData = selection => {
  const data = {
    audio: require('../data/audio.json'),
    video: require('../data/video.json'),
    event: require('../data/event.json'),
  };

  const searchParams = getSearchParams();
  const artistId = getConfig().artistId || searchParams.get('artist');

  if (artistId) {
    for (const prop of Object.keys(data)) {
      data[prop].artists = data[prop].artists.filter(artist => artist.id === artistId);
    }
  }

  return selection ? data[selection] : data;
};

export const getVideoList = (videoData, showAll) =>
  videoData.artists
    .flatMap(artist => artist.items)
    .filter(showAll ? is : isNotHidden);

export const getEventList = (eventData, showAll) =>
  eventData.artists
    .flatMap(artist => artist.items)
    .filter(showAll ? is : isNotHidden)
    // sort by date descending
    .sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    })
    // filter duplicates
    .filter((obj, index, array) => array.map(mapObj => mapObj.id).indexOf(obj.id) === index);

export const getDataParams = () => {
  const subDomain = getSubDomain();
  const isSecretSubdomain = subDomain === 'xxx';
  const isShuffleSubdomain = subDomain === 'ooo';
  const searchParams = getSearchParams();
  const hasSecretParameter = searchParams.has('xxx');
  const hasShuffleParameter = searchParams.has('ooo');
  const { showAll, shuffleLists } = getConfig();

  return {
    showAll: showAll || isSecretSubdomain || hasSecretParameter,
    shuffleLists: shuffleLists || isShuffleSubdomain || hasShuffleParameter,
  };
};
