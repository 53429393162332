export const shuffle = array => {
  let curId = array.length;
  // There remain elements to shuffle

  while (curId !== 0) {
    // Pick a remaining element
    const randId = Math.floor(Math.random() * curId);

    curId -= 1;
    // Swap it with the current element.
    const tmp = array[curId];

    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array;
};
