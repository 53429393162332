import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';

export const Image = ({ className, src, alt, onLoad }) => {
  const image = useRef();
  const [loaded, setLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setLoaded(true);
    onLoad?.();
  }, [
    onLoad,
  ]);

  return (
    <img
      className={classNames(
        'image',
        className,
        !loaded && 'invisible',
      )}
      src={src}
      alt={alt}
      ref={image}
      onLoad={handleLoad}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  onLoad: PropTypes.func,
};
