export const captureKeys = (event, element) => {
  switch (event.keyCode) {
    case 32: {
      event.preventDefault();
      // prevent double space event on focused audio element
      element.blur();
      if (element.paused) {
        element.play();
      } else {
        element.pause();
      }
      break;
    }
    case 37: {
      event.preventDefault();
      element.currentTime -= 5;
      break;
    }
    case 39: {
      event.preventDefault();
      element.currentTime += 5;
      break;
    }
    default:
      // eslint-disable-next-line no-console
      // console.log(event.keyCode);
  }
};
