import { useEffect } from 'react';

import { useDataContext } from '../../context/data-context';
import { getAudioElement, getNextTrack, loadAudio } from '../../helpers/common/audio';
import { captureKeys } from '../../helpers/common/control';
import { isTouch } from '../../helpers/common/screen';
import { attachEvents, unattachEvents } from './analyser';

import './Player.css';

export const Player = () => {
  const {
    initialTrack,
    currentTrack,
    setCurrentTrack,
    trackList,
  } = useDataContext();

  const handleEndedAudio = () => {
    const nextTrack = getNextTrack(trackList, currentTrack);

    loadAudio(nextTrack, true);
    setCurrentTrack(nextTrack);
  };

  useEffect(() => {
    loadAudio(initialTrack);
    if (!isTouch()) {
      attachEvents();
      return unattachEvents;
    }
  }, [initialTrack]);

  useEffect(() => {
    const handleKeydown = e => {
      captureKeys(e, getAudioElement());
    };

    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <>
      <canvas className="canvas" />
      <div className="controls">
        <audio
          className="audio"
          crossOrigin="anonymous"
          controls
          onEnded={handleEndedAudio}
        />
      </div>
    </>
  );
};
