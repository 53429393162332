import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const isExternal = path => /^https?:\/\/.+/.test(path) || /^\/\/.+/.test(path);

export const Link = ({
  to,
  children,
  className,
  style,
  onClick,
}) => {
  const content = children || to;
  const props = {
    className,
    style,
    onClick,
  };

  return isExternal(to) ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      {...props}
    >
      {content}
    </a>
  ) : (
    <NavLink
      className={({ isActive }) => (isActive ? 'active' : 'inactive')}
      to={to}
      {...props}
    >
      {content}
    </NavLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
