import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Link } from './Link';

export const File = ({
  path,
  children,
  className,
  style,
  onClick,
}) => {
  const handleClickLink = useCallback(e => {
    e.preventDefault();
    onClick();
  }, [
    onClick,
  ]);

  return (
    <Link
      className={className || 'file'}
      style={style}
      to={path}
      onClick={handleClickLink}
    >
      {children}
    </Link>
  );
};

File.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};
