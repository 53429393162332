const drawAnalyser = (canvas, analyser, mode = 'frequency') => {
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  canvas.width = bufferLength;
  canvas.height = 256;

  const ctx = canvas.getContext('2d');
  const barWidth = 1;
  const barDistance = 1.375;

  const paintCanvas = () => {
    if (mode === 'waveform') {
      analyser.getByteTimeDomainData(dataArray);
    } else {
      analyser.getByteFrequencyData(dataArray);
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    dataArray.forEach((item, index) => {
      const barHeight = item * 3 / 4;

      if (mode === 'waveform') {
        ctx.fillStyle = 'rgba(255, 170, 0, 1)';
        ctx.fillRect(index, canvas.height - barHeight, barWidth, 1);
      } else {
        ctx.fillStyle = `rgba(255, ${255 - item}, 0, ${1 - (item / canvas.height)})`;
        ctx.fillRect(index * barDistance, canvas.height, barWidth, 0 - barHeight);
      }
    });

    window.requestAnimationFrame(paintCanvas);
  };

  paintCanvas();
};

const connect = (audio, canvas) => {
  try {
    const AudioContext = window.AudioContext;
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaElementSource(audio);

    source.connect(analyser);
    analyser.connect(audioContext.destination);

    drawAnalyser(canvas, analyser);
  } catch (_error) {}
};

export const attachEvents = () => {
  document.addEventListener('click', handleClickBody);
};

export const unattachEvents = () => {
  document.removeEventListener('click', handleClickBody);
};

const handleClickBody = () => {
  connect(document.querySelector('.audio'), document.querySelector('.canvas'));
  unattachEvents();
};
