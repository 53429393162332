// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// import 'core-js/actual';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { DataContextProvider } from './context/data-context';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <DataContextProvider>
      <App />
    </DataContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
